exports.components = {
  "component---src-templates-about-page-index-tsx": () => import("./../../../src/templates/about-page/index.tsx" /* webpackChunkName: "component---src-templates-about-page-index-tsx" */),
  "component---src-templates-build-page-index-tsx": () => import("./../../../src/templates/build-page/index.tsx" /* webpackChunkName: "component---src-templates-build-page-index-tsx" */),
  "component---src-templates-build-page-taco-index-tsx": () => import("./../../../src/templates/build-page/taco/index.tsx" /* webpackChunkName: "component---src-templates-build-page-taco-index-tsx" */),
  "component---src-templates-build-page-tbtc-sdk-index-tsx": () => import("./../../../src/templates/build-page/tbtc-sdk/index.tsx" /* webpackChunkName: "component---src-templates-build-page-tbtc-sdk-index-tsx" */),
  "component---src-templates-earn-page-btc-index-tsx": () => import("./../../../src/templates/earn-page/btc/index.tsx" /* webpackChunkName: "component---src-templates-earn-page-btc-index-tsx" */),
  "component---src-templates-earn-page-index-tsx": () => import("./../../../src/templates/earn-page/index.tsx" /* webpackChunkName: "component---src-templates-earn-page-index-tsx" */),
  "component---src-templates-earn-page-liquidity-provider-index-tsx": () => import("./../../../src/templates/earn-page/liquidity-provider/index.tsx" /* webpackChunkName: "component---src-templates-earn-page-liquidity-provider-index-tsx" */),
  "component---src-templates-earn-page-token-holder-index-tsx": () => import("./../../../src/templates/earn-page/token-holder/index.tsx" /* webpackChunkName: "component---src-templates-earn-page-token-holder-index-tsx" */),
  "component---src-templates-ecosystem-page-index-tsx": () => import("./../../../src/templates/ecosystem-page/index.tsx" /* webpackChunkName: "component---src-templates-ecosystem-page-index-tsx" */),
  "component---src-templates-governance-page-index-tsx": () => import("./../../../src/templates/governance-page/index.tsx" /* webpackChunkName: "component---src-templates-governance-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/home-page/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-press-page-index-tsx": () => import("./../../../src/templates/press-page/index.tsx" /* webpackChunkName: "component---src-templates-press-page-index-tsx" */)
}

